<template>
  <div class="task-panel-root-container">
    <div class="task-panel-header-container">
      <Icon type="ios-arrow-dropleft" color="#fff" :size="40" @click="back" />
      <div class="task-panel-header-inner-container">
        <span style="font-size: 23px; color: #fff; margin-bottom: 10px"
          >#{{ boardName }}#</span
        >
        <span style="font-size: 15px; color: #c3c3c3"
          >来自{{ projectName }}</span
        >
      </div>
    </div>
    <div class="task-panel-main-container">
      <task-item
        v-for="item in taskSetList"
        :key="item.task_set_id"
        :dataSource="item"
        :projectId="projectId"
        @refresh="refresh"
      />
      <Button
        @click="isEdit = true"
        v-if="!isEdit"
        type="text"
        class="task-panel-btn"
        size="large"
        icon="md-add"
        >添加一个任务集</Button
      >
      <div v-else class="task-panel-edit-container">
        <my-input
          ref="formName"
          size="large"
          title
          placeholder="为任务集命名"
          v-model="taskSetName"
          :validate="nameValidate"
        />
        <div class="task-panel-row">
          <div
            class="task-panel-row"
            style="margin-right: 50px; cursor: pointer"
            @click="add"
          >
            <Icon
              style="margin-right: 10px"
              :size="20"
              color="#19be6b"
              type="md-checkmark-circle-outline"
            />
            <span style="font-size: 15px; font-weight: bold">添加任务集</span>
          </div>
          <div
            class="task-panel-row"
            style="cursor: pointer"
            @click="isEdit = false"
          >
            <Icon
              style="margin-right: 10px"
              :size="20"
              color="#ed4014"
              type="md-checkmark-circle-outline"
            />
            <span style="font-size: 15px; font-weight: bold">放弃</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import MyInput from "../../components/Form/MyInput";
import TaskItem from "./Item/TaskItem";
import moment from "moment";
export default {
  components: {
    "task-item": TaskItem,
    "my-input": MyInput,
  },
  data() {
    return {
      icons: icon,
      boardName: "",
      boardId: 0,
      projectId: "0",
      projectName: "",
      isEdit: false,
      taskSetName: "",
      taskSetList: [],
    };
  },
  mounted() {
    this.boardId = this.$router.currentRoute.params.tid;
    this.projectId = this.$router.currentRoute.params.pid;

    this.taskGetBoardDetailAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
      task_board_id: this.boardId,
    })
      .then((res) => {
        this.boardName = res.board_name;
        this.projectName = res.project_name;
      })
      .catch((error) => {
        console.log(error);
        this.$Message.error(error);
      });

    this.refresh();
  },
  methods: {
    ...mapActions({
      taskGetTaskSetListAction: "taskGetTaskSetList",
      taskGetBoardDetailAction: "taskGetBoardDetail",
      taskAddOrUpdateTaskSetAction: "taskAddOrUpdateTaskSet",
    }),
    back() {
      this.$router.push({
        name: "task-dashboard",
        params: {
          id: this.projectId,
        },
      });
    },
    add() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.taskAddOrUpdateTaskSetAction({
            employer_id: localStorage.getItem("employer_id"),
            task_board_id: this.boardId,
            task_set_id: -1,
            name: this.taskSetName,
          })
            .then((res) => {
              this.isEdit = false;
              this.taskSetName = "";
              this.refresh();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.taskSetName == "") {
          reject("请填写任务集名称");
        } else {
          resolve();
        }
      });
    },
    refresh() {
      this.taskGetTaskSetListAction({
        employer_id: localStorage.getItem("employer_id"),
        task_board_id: this.boardId,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
            for (let item2 of item.task_arr) {
              item2.address_arr = JSON.parse(item2.address_arr);
            }
          }
          this.taskSetList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.task-panel-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #283742;
  overflow: hidden;
}
.task-panel-header-container {
  background-color: #363d45;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
}
.task-panel-header-inner-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.task-panel-main-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 50px;
}
.task-panel-btn {
  color: #fff;
  min-width: 360px;
  margin-right: 20px;
}
.task-panel-btn:hover {
  color: #000;
}
.task-panel-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.task-panel-edit-container {
  display: flex;
  flex-direction: column;
  width: 360px;
  min-width: 360px;
  background-color: #e8e9ee;
  border-radius: 13px;
  padding: 10px;
}
</style>