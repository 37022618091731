<template>
  <div class="task-item-root-container">
    <div class="task-item-row">
      <span style="font-size: 15px; font-weight: bold; flex: 1">{{
        dataSource.name
      }}</span>
      <!-- <Icon style="cursor: pointer;" :size="20" type="ios-more" /> -->
    </div>
    <span style="font-size: 12px; font-weight: bold; margin-top: 10px"
      >创建于：{{ dataSource.create_time }}</span
    >
    <Divider style="background-color: #ccc" />
    <div class="task-item-main-container">
      <task-set-item
        v-for="item in dataSource.task_arr"
        :key="item.task_id"
        :dataSource="item"
        @click.native="goDetail(item)"
      />
      <Button
        @click="isEdit = true"
        v-if="!isEdit"
        size="large"
        type="primary"
        style="width: 90%; margin-top: 10px; min-height: 40px"
        >向任务集添加一个任务</Button
      >
      <div v-else class="task-item-edit-container">
        <my-input
          ref="formName"
          size="large"
          title
          placeholder="为任务命名"
          v-model="taskName"
          :validate="nameValidate"
        />
        <div class="task-item-edit-row">
          <div
            class="task-item-edit-row"
            style="margin-right: 50px; cursor: pointer"
            @click="add"
          >
            <Icon
              style="margin-right: 10px"
              :size="20"
              color="#19be6b"
              type="md-checkmark-circle-outline"
            />
            <span style="font-size: 15px; font-weight: bold">添加任务</span>
          </div>
          <div
            class="task-item-edit-row"
            style="cursor: pointer"
            @click="isEdit = false"
          >
            <Icon
              style="margin-right: 10px"
              :size="20"
              color="#ed4014"
              type="md-checkmark-circle-outline"
            />
            <span style="font-size: 15px; font-weight: bold">放弃</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyInput from "../../../components/Form/MyInput";
import TaskSetItem from "./TaskSetItem";
export default {
  components: {
    "task-set-item": TaskSetItem,
    "my-input": MyInput,
  },
  props: {
    dataSource: Object,
    projectId: String,
  },
  data() {
    return {
      taskName: "",
      isEdit: false,
      taskList: [],
    };
  },
  methods: {
    ...mapActions({
      taskAddTaskAction: "taskAddTask",
    }),
    add() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.taskAddTaskAction({
            employer_id: localStorage.getItem("employer_id"),
            task_set_id: this.dataSource.task_set_id,
            name: this.taskName,
          })
            .then((res) => {
              this.isEdit = false;
              this.taskName = "";
              this.$emit("refresh");
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    goDetail(item) {
      this.$router.push({
        name: "task-info",
        params: {
          tid: item.task_id,
          pid: this.projectId,
        },
      });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.taskName == "") {
          reject("请填写任务名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.task-item-root-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 360px;
  min-width: 360px;
  background-color: #e8e9ee;
  border-radius: 13px;
  padding: 20px;
  margin-right: 30px;
  overflow: hidden;
}
.task-item-row {
  display: flex;
  flex-direction: row;
}
.task-item-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  overflow-y: scroll;
}
.task-item-edit-container {
  display: flex;
  flex-direction: column;
  width: 360px;
  min-width: 360px;
  padding: 15px;
}
.task-item-edit-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>